export const INTEGRATED_CHECK_TYPES = ["non reference", "integrated", "neotas integrated check", "integrated check"];

// * In case integrated check is the same as the tool and require same licenses we need to set an integrated check name equal to the tool name
export const integratedCheckList = Object.freeze({
  EDD: "edd",
  // next values may be changed to any other:
  CORPORATE_RECORDS: "corporate records",
  HOOYU: "hooyu",
  DBS_BASIC: "dbs basic",
  TRANSUNION: "transunion",
  LEXIS_BRIDGER: "lexis bridger",
  NEOTAS: "neotas",
  SEARCH_ENGINE: "search engine",
});

// different names for tools set custom values here
// (it shouldn't be equal to check name but can)
export const toolList = Object.freeze({
  EDD: integratedCheckList.EDD,
});

export const integratedCheckIdList = Object.freeze({
  [integratedCheckList.EDD]:'92b5f0c9-f341-485e-9c70-87d6276491f8',
  [integratedCheckList.CORPORATE_RECORDS]: "07f9b595-958f-4402-907f-31d4ebb4a369",
  [integratedCheckList.HOOYU]: "2b1163fe-baec-431d-8d38-ef791cb623fe",
  [integratedCheckList.DBS_BASIC]: "36dfd7c8-5750-4ef4-a591-e441e7d1e0c8",
  [integratedCheckList.TRANSUNION]: "6db183f9-6b16-47d0-81d1-514728c4ff2f",
  [integratedCheckList.LEXIS_BRIDGER]: "8526f91f-5b51-4703-84d4-7f97ba785e42",
  [integratedCheckList.NEOTAS]:"b875f10c-3cf1-413b-bc08-971cf7a460a6",
  [integratedCheckList.SEARCH_ENGINE]: "bda2c73c-7490-4ae2-9b4a-2c1035d83f3d",
});

export const TOOLS_LICENSE_MAP = {
  "lexis-bridger-tool": ["lexis_bridger__adverse_pep_sacntion"],
  "company-name": ["bvd__corp_record", "db__corp_record"],
  "search-engine-tool": ["search_engine__search_engine_"],
  "gdc-person": ["gdc__person"],
  [toolList.EDD]: ["edd"],
};

export const CHECK_TOOLS = [ "lexis-bridger-tool", "company-name", "search-engine-tool", "gdc-person" ];

export const TOOL_TO_CHECK_NAME_MAP = {
  "company-name": ["corporate record", "corporate records", "poe"],
  "lexis-bridger-tool": ["lexis bridger", "lexis-bridger", "kyc"],
  "search-engine-tool": ["search engine"],
  "gdc-person": ["id verification"]
};
