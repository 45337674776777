import axios from "@/axios";
import { orderBy } from "lodash";
import { formatToolResponsePoolData } from "@shared/tools/utils/helpers.js"

export const ProfileStore = {

    state: {
        refChecks: []
    },

    mutations: {
        SET_REF_CHECKS: (state, payload) => {
            state.refChecks = payload;
        },
        REFRESH_CHECK_STATUS: (state, payload) => {
            state.refChecks[payload.checkId] = {
                ...state.refChecks[payload.checkId],
                ...payload.statusModel
            };
        },
    },

    getters: {
        getRefChecks: state => state.refChecks,
    },

    actions: {
        refreshCheckToolSelfStatus: async ({ dispatch }, { caseId, entityId, checkId, searchResults = [], options = {} }) => {
            try {
                const url = `case/${caseId}/entity/${entityId}/check/${checkId}`
                const results = formatToolResponsePoolData(searchResults);
                const shouldUpdateStatus = results.some(x => x.isFromSource);
                if (shouldUpdateStatus) {
                    await axios.get(url);
                    if (options.refreshCheckStatus) {
                        await dispatch('refreshCheckStatus', { checkId });
                    }
                }
            } catch (error) {
                console.log(error);
            }
        },
        refreshCheckStatus: async ({ commit }, payload) => {
            try {
                const statusesList = await getCheckStatuses(payload.checkId);
                const statusModel = formatCheckStatuses(statusesList);
                commit('REFRESH_CHECK_STATUS', { checkId: payload.checkId, statusModel })
            } catch (error) {
                console.log(error);
            }
        },
        fetchRefChecks: async ({ commit }, payload) => {
            try {
                const { data } = await axios(`case/${payload.caseId}/case_entity/${payload.entityId}/check`);

                let checksStatuses = [];
                try {
                    const promises = data.data.map(check => 
                        getCheckStatuses(check.id)
                    );

                    checksStatuses = await Promise.all(promises);
                } catch(error) {
                    console.log(error);
                }

                data.data = data.data.map(el => {
                    let statuses = checksStatuses.find(x => x.id == el.id);
                    const statusData = formatCheckStatuses(statuses?.data || []);
                    return Object.assign({}, el, statusData)
                })

                
                if (payload.entityId == payload?.caseEntity?.id) {
                    data.data = data.data.filter((res) => res.check_category!='Integrated Check')
                }
                // const sortedChecks =  orderBy(data.data, [check => (check.check_name || '').toLowerCase()], ['asc']);
                const sortedChecks = orderBy(data.data, [check => (check.order || 0)], ['asc']);
                commit('SET_REF_CHECKS', sortedChecks)
            } catch (error) {
                console.log(error, ">>>>Error")
            }
        },

    }

};
                
const getCheckStatuses = (checkId) => {
    try {
        return new Promise((res, rej) => {
            axios.get(`workflow/${checkId}/status`)
                .then(e => res({ status: true, id: checkId, data: e.data?.data }))
                .catch(err => {
                    console.log(`Error fetching Check Statuses`)
                    rej({ status: false, error: err.message })
                })
        })
    } catch(error) {
        console.log(error);
    }
}

const formatCheckStatuses = (checkStatuses) => {

    const _checkStatuses = orderBy(checkStatuses, "status_order", "asc");

    const obj =  {
        statuses: _checkStatuses.map((status) => ({
            ...status,
            name: status.status_name,
            label: status.status_name,
            value: status.status_id,
            id: status.status_id,
            styles: {
                backgroundColor: status.status_colour || "#0000FF",
                color: status.text_color || "#fff",
            },
        })),
        references: [],
        status: ''
    }

    const currStatus = obj.statuses.find(x => x.current)

    if (currStatus) {
        obj.status = currStatus;
        obj.references.push({
            status_color: currStatus.status_colour || 'blue',
            status_name: currStatus.status_name
        });
    }

    return obj;
};
