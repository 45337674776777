<template>
  <div class="ui-toggle">
    <label class="switch">
      <input 
        type="checkbox"
        :checked="selected"
        :disabled="disabled"
        @change="handleChange"
      >
      <span
        class="slider round"
        :class="{
          'cursor-pointer': !disabled,
          'slider--disabled': disabled && !selected,
        }"
      ></span>
    </label>
  </div>
</template>

<script>
export default {
  name: "UIToggle",

  props: {
    selected: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    handleChange(event) {
      this.$emit("select", event.target.checked);

      // fix for checked status:
      this.$nextTick(() => {
        event.target.checked = this.selected;
      });
    },
  },
}
</script>

<style lang="scss" scoped>
  .ui-toggle {
    display: flex;
    align-items: center;
    width: 50px;
  

  .switch {
        position: relative;
        display: inline-block;
    }

    .switch input { 
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc; // TODO Change to theme color
        -webkit-transition: .4s;
        transition: .4s;
        width: 44px;
        height: 21px;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 17px;
        width: 17px;
        left: 5px;
        bottom: 2px;
        background-color: white; // TODO Change to theme color
        -webkit-transition: .4s;
        transition: .4s;
    }

    input:checked + .slider:not(.slider--disabled) {
        background-color: #2196F3; // TODO Change to theme color
    }

    input:focus + .slider {
        box-shadow: 0 0 1px #2196F3;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(18px);
        -ms-transform: translateX(18px);
        transform: translateX(18px);
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
    }
}
</style>
